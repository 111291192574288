import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'Main',
    isTitle: true
  },
  {
    label: 'Dashboard',
    icon: 'home',
    link: '/dashboard'
  },
  {
    label: 'Stations & Batteries',
    isTitle: true
  },
  {
    label: 'All Stations',
    icon: 'database',
    subItems: [
      {
        label: 'All Stations',
        link: '/stations',
      },
      {
        label: 'Add New',
        link: '/apps/email/read'
      }
    ]
  },
  {
    label: 'All Drivers',
    icon: 'users',
    link: '/drivers',
  },
  {
    label: 'All Batteries',
    icon: 'battery',
    link: '/batteries',
  },
  {
    label: 'All Cities',
    icon: 'layers',
    link: '/cities',
    badge: {
      variant: 'primary',
      text: 'New',
    }
  },
  {
    label: 'Reports & Analytics',
    isTitle: true
  },
  {
    label: 'Reports',
    icon: 'download-cloud',
    subItems: [
      {
        label: 'Transactions Report',
        link: '/reports/transactions',
      },
      {
        label: 'Drivers Report',
        link: '/reports/drivers',
      }
    ]
  },
  {
    label: 'Transactions Summary',
    icon: 'list',
    subItems: [
      {
        label: 'Station Report',
        link: '/charts-graphs/apexcharts',
      },
      {
        label: 'Driver Report',
        link: '/charts-graphs/chartjs',
      }
    ]
  },
  // {
  //   label: 'Charts & graphs',
  //   icon: 'pie-chart',
  //   subItems: [
  //     {
  //       label: 'ApexCharts',
  //       link: '/charts-graphs/apexcharts',
  //     },
  //     {
  //       label: 'ChartJs',
  //       link: '/charts-graphs/chartjs',
  //     },
  //   ]
  // },
 
  {
    label: 'Payments & Subscriptions',
    isTitle: true
  },
  {
    label: 'Subscriptions',
    icon: 'bell',
    link: '/subscriptions',
  },
  {
    label: 'Payments',
    icon: 'alert-circle',
    link: '/payments',
  },
  {
    label: 'Packages & Recharges',
    icon: 'settings',
    link: '/manage-users',
  },
  {
    label: 'Accounting & Invoice',
    isTitle: true
  },
  {
    label: 'Emi',
    icon: 'list',
    link: '/accounting',
  },
  {
    label: 'Zoruk EV ',
    isTitle: true
  },
  {
    label: 'All Visits',
    icon: 'user',
    link: '/zorukev',
  },
  {
    label: 'Chat Support',
    isTitle: true
  },
  {
    label: 'Contact Enquiry',
    icon: 'message-circle',
    link: '/contact',
  },
  {
    label: 'New Messages',
    icon: 'send',
    link: '/contact',
  },
  {
    label: 'User & Settings',
    isTitle: true
  },
  {
    label: 'Agent Location',
    icon: 'map-pin',
    link: '/manage-users/agent-location',
  },
  {
    label: 'Manage Users',
    icon: 'users',
    link: '/manage-users',
  },
  {
    label: 'Manage Settings',
    icon: 'settings',
    link: '/manage-settings',
  }

  // {
  //   label: 'Pages',
  //   isTitle: true
  // },
  // {
  //   label: 'Special pages',
  //   icon: 'book',
  //   subItems: [
  //     {
  //       label: 'Blank page',
  //       link: '/general/blank-page',
  //     },
  //     {
  //       label: 'Faq',
  //       link: '/general/faq',
  //     },
  //     {
  //       label: 'Invoice',
  //       link: '/general/invoice',
  //     },
  //     {
  //       label: 'Profile',
  //       link: '/general/profile',
  //     },
  //     {
  //       label: 'Pricing',
  //       link: '/general/pricing',
  //     },
  //     {
  //       label: 'Timeline',
  //       link: '/general/timeline',
  //     }
  //   ]
  // },
  // {
  //   label: 'Authentication',
  //   icon: 'unlock',
  //   subItems: [
  //     {
  //       label: 'Login',
  //       link: '/auth/login',
  //     },
  //     {
  //       label: 'Register',
  //       link: '/auth/register',
  //     },
  //   ]
  // },
  // {
  //   label: 'Error',
  //   icon: 'cloud-off',
  //   subItems: [
  //     {
  //       label: '404',
  //       link: '/error/404',
  //     },
  //     {
  //       label: '500',
  //       link: '/error/500',
  //     },
  //   ]
  // },
];
