// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // API_HOST: "https://1w9aypdcl7.execute-api.ap-south-1.amazonaws.com/stag",
  API_HOST: "https://api-admin.instaswap.in",

  firebase: {
    apiKey: "AIzaSyAqRByL1-qj7TMGUqcN21DjipTsYpK2X6Y",
    authDomain: "instaswap-5b9a5.firebaseapp.com",
    projectId: "instaswap-5b9a5",
    storageBucket: "instaswap-5b9a5.appspot.com",
    messagingSenderId: "962706030553",
    appId: "1:962706030553:web:603dca3f9c7f6cda0ca3c8",
    measurementId: "G-N38M29NB8Q",
    vapidKey: "BJ3mEmz3hFTGHr-Z_arSY-X8S6Pv2OfQ4vqG4PSWTYYzDpKwAIhx9vYqU1zbKA4ILhLuPu4Ph3orVOZ8J6t9WGs"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
